import React from "react"
import PropTypes from "prop-types"

const ApiParameterItem = props => {
  return (
    <tr>
      <td>
        <code>{props.parameter}</code>
      </td>
      <td>{props.type}</td>
      <td>{props.children}</td>
    </tr>
  )
}

ApiParameterItem.propTypes = {
  parameter: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ApiParameterItem
