import _ from "lodash"
import transformFunctions from "./functions"

// method: post, get, postWithFile
// defaultOptions: http parameters for http post/postWithFile/get methods in
// customTransformations: pass a list of transformations you want

class Transformer {
  constructor({ method = "get", options = {}, transformations = null } = {}) {
    this.httpMethod = method
    this.defaultOptions = options
    this.customTransformations = transformations // FIX custom transformations do not work at the moment
  }

  transform(options = {}) {
    const mergedOptions = Object.assign({}, this.defaultOptions, options)
    const transformations = this.transformations()
    // console.log(transformations)

    const result = {}
    _.each(transformations, tf => {
      if (transformFunctions[tf.language][tf.library][this.httpMethod]) {
        result[`${tf.language}-${tf.library}`] = {
          code: transformFunctions[tf.language][tf.library][this.httpMethod](
            mergedOptions
          ),
        }
      }
    })

    return result
  }

  transformations() {
    return this.customTransformations || this.allTransformations()
  }

  allTransformations() {
    return [
      { language: "bash", library: "curl" },
      { language: "javascript", library: "quakApi" },
      { language: "javascript", library: "axios" },
      { language: "javascript", library: "request" },
      { language: "javascript", library: "fetch" },
      { language: "javascript", library: "jquery" },
      { language: "ruby", library: "netHttp" },
      { language: "ruby", library: "restClient" },
      { language: "python", library: "requests" },
      { language: "php", library: "httpRequest" },
    ]
  }
}

export default Transformer
