import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  "${attr}": ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n}"

  return `import requests
import json

url = "${options.apiBaseUrl}/${options.endpoint}"

data = json.dumps(${dataOutput})

headers = {
  'X-API-Key': "${options.apiKey}",
  'Content-Type': "application/json"
}

response = requests.post(url, data=data, headers=headers)
print(response.text)`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `    "${attr}": ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n  }"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `import requests
import json

url = "${options.apiBaseUrl}/${options.endpoint}"
headers = { 'X-API-Key': "${options.apiKey}" }
files = { "file": open('${options.data.file}', 'rb') }

data = {
  "params": json.dumps(${dataOutput})
}

response = requests.post(url, files=files, headers=headers, data=data)
print(response.text)`
}

export default {
  post,
  postWithFile,
}
