import React, { useContext } from "react"
import PropTypes from "prop-types"
import CodeBlock from "./code-block"

import ApiKeyContext from "../context/api-key-context"

const CodeTransformer = props => {
  const apiKeyContext = useContext(ApiKeyContext)

  const options = props.options
  if (apiKeyContext.apiKey) {
    options.apiKey = apiKeyContext.apiKey
  } else {
    options.apiKey = props.transformer.defaultOptions.apiKey
  }

  const code = props.transformer.transform(options)

  return <CodeBlock code={code} />
}

CodeTransformer.propTypes = {
  transformer: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
}

export default CodeTransformer
