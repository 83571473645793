import quakApi from "./quak-api"
import request from "./request"
import axios from "./axios"
import fetch from "./fetch"
import jquery from "./jquery"

export default {
  quakApi,
  request,
  axios,
  fetch,
  jquery,
}
