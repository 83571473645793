import React, { useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import apiSettings from "../utils/api-settings"

import ApiKeyContext from "../context/api-key-context"

const Container = styled.div`
  display: flex;
  margin-top: 10px;
`

const Input = styled.input`
  width: 280px;
  padding: 8px 15px;
  font-size: 13px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #f7f7f7;

  ${media.lessThan("medium")`
    width: 100%;
  `}
`

const ApiKeyField = props => {
  const apiKeyContext = useContext(ApiKeyContext)

  function handleApiKeyChange(event) {
    const key = event.target.value === "" ? null : event.target.value
    apiKeyContext.setApiKey(key)
  }

  return (
    <div>
      If you already have a Quak API key, put it in here and all the examples
      will use it:
      <Container>
        <Input
          type="text"
          name="name"
          placeholder={apiSettings.apiKeyPlaceholder}
          onChange={handleApiKeyChange}
          value={apiKeyContext.apiKey || ""}
        />
      </Container>
    </div>
  )
}

export default ApiKeyField
