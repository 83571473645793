import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `    ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n  }"

  return `const request = require("request");

const options = {
  method: 'POST',
  url: '${options.apiBaseUrl}/${options.endpoint}',
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': '${options.apiKey}'
  },
  body: ${dataOutput},
  json: true
};

request(options, function (error, response, body) {
  if (error) throw new Error(error);
  console.log(body);
});
`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `      ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n    }"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `const fs = require("fs");
const request = require("request");

const options = {
  url: "${options.apiBaseUrl}/${options.endpoint}",
  headers: {
    "X-API-Key": "${options.apiKey}"
  },
  formData: {
    params: JSON.stringify(${dataOutput}),
    file: fs.createReadStream("${options.data.file}")
  }
};

request.post(options, function(err, res, body) {
  if (err) { console.log(err); }
  console.log(body);
});`
}

export default { post, postWithFile }
