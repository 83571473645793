import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `    ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n  }"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `require 'rest-client'

RestClient.post(
  '${options.apiBaseUrl}/${options.endpoint}',
  ${dataOutput},
  {
    "X-API-Key": "${options.apiKey}"
  }
)`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `      ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n    }"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `require 'rest-client'
require 'json'

RestClient.post(
  '${options.apiBaseUrl}/${options.endpoint}',
  {
    params: ${dataOutput}.to_json,
    file: File.new('${options.data.file}')
  },
  {
    "X-API-Key": "${options.apiKey}"
  }
)`
}

export default {
  post,
  postWithFile,
}
