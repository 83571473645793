import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n}"

  return `require 'uri'
require 'json'
require 'net/http'

url = URI("${options.apiBaseUrl}/${options.endpoint}")
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

data = ${dataOutput}

request = Net::HTTP::Post.new(url)
request["X-API-Key"] = '${options.apiKey}'
request["Content-Type"] = 'application/json'
request.body = data.to_json
response = http.request(request)
json = JSON.parse(response.read_body)`
}

export default { post }
