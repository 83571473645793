import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n}"

  return `const axios = require('axios');

const url = "${options.apiBaseUrl}/${options.endpoint}";

const data = ${dataOutput};

const options = {
  headers: {
    "X-API-Key": "${options.apiKey}"
  }
};

const response = axios.post(url, data, options).then(response => {
  console.log(response);
}).catch(error => {
  console.log(error);
});`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n}"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `const fs = require("fs");
const FormData = require("form-data");
const axios = require("axios");

const url = "${options.apiBaseUrl}/${options.endpoint}"

const data = ${dataOutput};

const form = new FormData();
form.append("params", JSON.stringify(data));
form.append("file", fs.createReadStream("${options.data.file}"));

const headers = Object.assign({
  "X-API-Key": "${options.apiKey}"
}, form.getHeaders());

axios.post(url, form, { headers }).then(response => {
  console.log(response);
}).catch(error => {
  console.log(error);
});`
}

export default { post, postWithFile }
