import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  "${attr}": ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n}"

  return `$request = new HttpRequest();
$request->setUrl('${options.apiBaseUrl}/${options.endpoint}');
$request->setMethod(HTTP_METH_POST);

$request->setHeaders(array(
  'Content-Type' => 'application/json',
  'X-API-Key' => '${options.apiKey}'
));

$request->setBody('${dataOutput}');

$response = $request->send();
echo $response->getBody();
`
}

export default { post }
