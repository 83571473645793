import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n}"

  return `const data = ${dataOutput};

const headers = {
  "Content-Type": "application/json",
  "X-API-Key": "${options.apiKey}"
}

fetch("${options.apiBaseUrl}/${options.endpoint}", {
  method: 'POST',
  body: JSON.stringify(data),
  headers: headers
})
.then(response => response.json())
.then(function (response) {
   console.log(response)
});`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n}"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `// first create a blob from data URI, base64 string or getUserMedia
// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

const blob = new Blob([BLOB_DATA_HERE], { type : 'audio/mpeg' });

const params = ${dataOutput};

const formData = new FormData();
formData.append("params", JSON.stringify(params));
formData.append("file", blob);

const headers = {
  "X-API-Key": "${options.apiKey}"
}

fetch("${options.apiBaseUrl}/${options.endpoint}", {
  method: 'POST',
  body: formData,
  headers: headers,
  mode: "cors"
})
.then(response => response.json())
.then(response => {
   console.log(response)
});`
}

export default { post, postWithFile }
