import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocItem from "../../components/api-doc-item"
import ApiDocFooter from "../../components/api-doc-footer"
import ApiParameterTable from "../../components/api-parameter-table"
import ApiKeyField from "../../components/api-key-field"
import CodeBlock from "../../components/code-block"
import CodeTransformer from "../../components/code-transformer"

import apiSettings from "../../utils/api-settings"
import Transformer from "../../utils/code-transformer/transformer"

const endpoint = "play/url"

const transformer = new Transformer({
  method: "post",
  options: {
    endpoint: endpoint,
    apiBaseUrl: apiSettings.apiBaseUrl,
    apiKey: apiSettings.apiKey,
  },
})

const ApiPlayUrlPage = () => (
  <Layout title="Play URL API" sidebar={ApiSidebar()}>
    <SEO title="Play URL API" />

    <p>
      Use the <i>Play URL API</i> to play audio files from URLs with optional
      effects on your Sonos speakers. Just replace{" "}
      <code>{apiSettings.apiKeyPlaceholder}</code> with your own key.
    </p>

    <p>
      To use the <i>Play URL API</i> you need credits. One credit is necessary
      to play a single file from URL.{" "}
      <Link to="/pricing/">Learn more about credits here.</Link> No credits are
      charged when previewing notifications.
    </p>

    <ApiKeyField />

    <ApiDocItem>
      <h3>Endpoint</h3>
      <CodeBlock
        platform="bash"
        code={{
          bash: {
            code: `POST  ${apiSettings.apiBaseUrl}/${endpoint}`,
            codeToCopy: `${apiSettings.apiBaseUrl}/${endpoint}`,
          },
        }}
        showPlatformPicker={false}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Basic example</h3>
      <p>
        This will play the audio file on your given URL on your Sonos speakers.{" "}
        <code>url</code> is the only required parameter.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Use intro and outro sounds</h3>
      <p>
        This will play the defined intro and outro sounds. You can set{" "}
        <code>intro</code> and/or <code>outro</code> to a sound from{" "}
        <Link to="/api/list-sounds/">this list </Link>. You can pass{" "}
        <code>null</code> if you don't want to use a intro/outro sound to
        override your global default.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
            intro: "quakquak",
            outro: "walkie-talkie",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Set volume & speakers</h3>
      <p>
        Set <code>targets</code> (speakers, groups, ...) and <code>volume</code>{" "}
        to override your global defaults.{" "}
        <Link to="/api/list-targets/">
          Get a list of all available targets.
        </Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
            targets: "livingroom",
            volume: 10,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play on multiple targets</h3>
      <p>
        To play on multiple targets at the same time, set the{" "}
        <code>targets</code> parameters like this.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
            targets: ["livingroom", "bath"],
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play with audio effects</h3>
      <p>
        Apply e.g. <code>speed</code> and <code>reverb</code> audio effects to
        the message itself. Learn more about effects in the{" "}
        <i>Request Parameters</i> section below.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
            speed: 30,
            reverb: 50,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Preview mode</h3>
      <p>
        If you just want to preview the playback, you can pass{" "}
        <code>preview</code> and nothing will be played on your Sonos speakers.
        But the JSON response will include an URL to the audio file.{" "}
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
            preview: true,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play only on specific days and times</h3>
      <p>
        Use the <code>days</code> and <code>hours</code> parameters to specify
        when to play the audio notification. If week day and time (
        <a href="https://app.quak.sh/settings" target="_blank">
          check your time zone
        </a>
        ) does not match nothing will be played. Notifications will not be
        queued.
      </p>
      <p>
        Let's say you don't want to play notifications in the night because...
        well... you are sleeping. It would be a waste of energy and Quak credits
        if the audio notification would be played 24/7.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            url: "https://cdn.quak.sh/demo/lion.mp3",
            days: "mo-fr",
            hours: "8-18",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Request Parameters</h3>
      <p>This is a full list of the request parameters you can use:</p>
      <ApiParameterTable type="URL" />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Example Response</h3>
      <p>
        If the request was successful you will receive a response like this one.
        The <i>uuid</i> is the unique identifier you can use to get updates
        regarding playback status. Directly after submitting <i>status</i> is{" "}
        <b>PENDING</b>. Only if you use preview mode the <i>status</i> is{" "}
        <b>DONE</b>.
      </p>
      <CodeBlock
        platform="json"
        code={{
          json: {
            code: `{
  "uuid": "32ff0e1c-ceb1-4fa2-be50-0249578f8a04",
  "createdAt": "2019-05-28T15:25:18.303Z",
  "type": "URL",
  "status": "PENDING",
  "audioUrl": "https://quakquak.s3.eu-central-1.amazonaws.com/out/2704/f68a/2704f68a-458f-4af1-aff4-1d25e12b23c2.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIV5WXHY5MSMXPN7Q%2F20190528%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20190528T152519Z&X-Amz-Expires=180&X-Amz-Signature=5ade2b5a171533e1d9094a8ffa8fb98d27be8bfc830b9dde93bf490b916cb30f&X-Amz-SignedHeaders=host",
  "audioId": "2704f68a-458f-4af1-aff4-1d25e12b23c2",
  "audioUrlExpiresAt": "2019-05-28T15:28:19.340Z",
  "length": 2.59,
  "credits": 1,
  "fromCache": false,
  "players": [
    {
      "uuid": "f89fcd4d-05d1-4231-a954-ca5f5cead5a1",
      "slug": "livingroom",
      "name": "Livingroom",
      "provider": "SONOS",
      "status": "PENDING"
    }
  ],
  "params": {
    "skipProcessing": false,
    "intro": "quakquak",
    "outro": "walkie-talkie",
    "delay": 200,
    "volume": 30,
    "targets": [
      ["livingroom"]
    ],
    "speed": 0,
    "tempo": 0,
    "pitch": 0,
    "reverb": 0,
    "reverse": false,
    "preview": false,
    "priority": false,
    "skipCache": false,
    "url": "https://cdn.quak.sh/demo/lion.mp3"
  }
}
`,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocFooter active="/api/play-url/" />
  </Layout>
)

export default ApiPlayUrlPage
