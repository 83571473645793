import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  const endpointsCommandMapping = {
    "play/sound": "play.sound",
    "play/clip": "play.clip",
    "play/text": "play.text",
    "play/url": "play.url",
  }

  let apiCommand = endpointsCommandMapping[options.endpoint]

  dataOutput += "\n}"

  return `// npm install @quak/api OR yarn add @quak/api
const QuakApi = require("@quak/api");

const api = new QuakApi("${options.apiKey}");

const data = ${dataOutput};

api.${apiCommand}(data).then(function (response) {
  console.log(response.error);
  console.log(response.data);
});`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n}"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `// npm install @quak/api OR yarn add @quak/api
const QuakApi = require("@quak/api");
const fs = require("fs");

const api = new QuakApi("${options.apiKey}");

const data = ${dataOutput};

const file = fs.createReadStream("${options.data.file}");

api.play.file(data, file).then(function (response) {
  console.log(response.error);
  console.log(response.data);
});`
}

export default { post, postWithFile }
