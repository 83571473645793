import styled from "styled-components"

const ApiDocItem = styled.div`
  h3 {
    margin-bottom: 5px;
  }

  code.value {
    color: #fb251b;
  }
`

export default ApiDocItem
