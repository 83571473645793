import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ApiParameterItem from "./api-parameter-item"
import Table from "./table"
import _ from "lodash"

const ApiParameterTable = props => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.type === "SOUND" && (
            <ApiParameterItem parameter="sound" type="string">
              The sound you want to play.{" "}
              <Link to="/api/list-sounds/">
                Get a list of all available sounds.
              </Link>{" "}
              Example: <code>"quakquak"</code>{" "}
              <strong>This parameter is required.</strong>
            </ApiParameterItem>
          )}

          {props.type === "CLIP" && (
            <ApiParameterItem parameter="clip" type="string">
              The clip you want to play.{" "}
              <Link to="/api/list-clips/">
                Get a list of all available clips.
              </Link>{" "}
              Example: <code>"my-custom-clip"</code>{" "}
              <strong>This parameter is required.</strong>
            </ApiParameterItem>
          )}

          {props.type === "TEXT" && (
            <ApiParameterItem parameter="text" type="string">
              The text message you want to play. Example:{" "}
              <code>"Hello you super nice to watch human beeing."</code> Max.
              500 characters allowed.
              <strong>This parameter is required.</strong>
            </ApiParameterItem>
          )}

          {props.type === "URL" && (
            <>
              <ApiParameterItem parameter="url" type="string">
                URL to an audio file. Example:{" "}
                <code>"http://bit.ly/quak-lion-mp3"</code> Max file size: 1 MB,
                supported file formats: mp3 (preferred), wav, ogg.{" "}
                <strong>This parameter is required.</strong>
              </ApiParameterItem>

              <ApiParameterItem parameter="skipProcessing" type="boolean">
                If you have ready to play audio files somewhere set this
                parameter to <code>true</code> and the Quak API server will not
                download the audio file and therefore it will not be processed (
                <code>intro</code>, <code>outro</code>, effect params). Features
                are very limited in that case but it's faster as Sonos will play
                the file directly (mp3 & wav only). Default: <code>false</code>
              </ApiParameterItem>
            </>
          )}

          <ApiParameterItem parameter="targets" type="string or array">
            Speakers where to play the notification.{" "}
            <Link to="/api/list-targets/">
              Get a list of all available targets
            </Link>
            . Example: <code>"all"</code> or <code>["livingroom", "bath"]</code>
          </ApiParameterItem>

          <ApiParameterItem parameter="volume" type="integer">
            Speaker volume from <code>1</code> to <code>100</code>. Please note
            that Sonos currently applies a minimum volume of around{" "}
            <code>15</code> because quieter notifications are hard to hear.
          </ApiParameterItem>

          {_.includes(["TEXT", "URL", "FILE"], props.type) && (
            <>
              <ApiParameterItem parameter="intro" type="string">
                A sound that will be played before the message itself. Example:{" "}
                <code>"quakquak"</code>{" "}
                <Link to="/api/list-sounds/">
                  Get a list of all available sounds.
                </Link>{" "}
                Pass <code>null</code> to disable intro sound.
              </ApiParameterItem>

              <ApiParameterItem parameter="outro" type="string">
                A sound that will be played after the message itself. Example:{" "}
                <code>"walkie-talkie"</code>{" "}
                <Link to="/api/list-sounds/">
                  Get a list of all available sounds.
                </Link>{" "}
                Pass <code>null</code> to disable outro sound.
              </ApiParameterItem>
            </>
          )}

          <ApiParameterItem parameter="preview" type="boolean">
            Set to <code>true</code> if you want to preview the notification.
            Nothing will be played on your Sonos speakers. Default:{" "}
            <code>false</code>
          </ApiParameterItem>

          <ApiParameterItem parameter="priority" type="boolean">
            Set to <code>true</code> if you want to play the notification with
            priority. Priority notifications will stop all currently playing
            notifications. Default: <code>false</code>
          </ApiParameterItem>

          <ApiParameterItem parameter="days" type="string">
            If set, the notification will only be played if the current day
            matches. Possible values: <code>"mo"</code>, <code>"tu"</code>,{" "}
            <code>"we"</code>, <code>"th"</code>, <code>"fr"</code>,{" "}
            <code>"sa"</code>, <code>"su"</code> or a list like{" "}
            <code>"mo,we,fr"</code> or a range like <code>"mo-fr"</code>.
            Default: <code>null</code>
          </ApiParameterItem>

          <ApiParameterItem parameter="hours" type="string">
            If set, the notification will only be played if the current hour
            matches. 24 hour format is used. Possible values: <code>"0"</code>{" "}
            to <code>"23"</code>. Whitelist multiple hours: <code>"8,18"</code>{" "}
            (valid: 08:13 am, 06:45 pm) or a range: <code>"8-18"</code> (valid:
            8.10 am, 1.50 pm, 6:30 pm) or multiple ranges:{" "}
            <code>"6-8,18-22"</code>. Default: <code>null</code>
          </ApiParameterItem>

          {_.includes(["TEXT", "URL", "FILE"], props.type) && (
            <>
              <ApiParameterItem parameter="speed" type="integer">
                Audio effect that will be applied to the audio notification. It
                will change tempo and pitch at the same time. Range:{" "}
                <code>-100</code> to <code>100</code> Default: <code>0</code>
              </ApiParameterItem>

              <ApiParameterItem parameter="tempo" type="integer">
                Audio effect that will be applied to the audio notification. It
                will change the message length without affecting pitch. Range:{" "}
                <code>-100</code> to <code>100</code> Default: <code>0</code>
              </ApiParameterItem>

              <ApiParameterItem parameter="pitch" type="integer">
                Audio effect that will be applied to the audio notification. It
                will change the pitch without affecting the tempo. Range:{" "}
                <code>-100</code> to <code>100</code> Default: <code>0</code>
              </ApiParameterItem>

              <ApiParameterItem parameter="reverb" type="integer">
                Audio effect that will be applied to the audio notification.
                Range: <code>0</code> to <code>100</code> Default:{" "}
                <code>0</code>
              </ApiParameterItem>

              <ApiParameterItem parameter="reverse" type="boolean">
                Audio effect that will be applied to the audio notification.
                Default: <code>false</code>
              </ApiParameterItem>

              <ApiParameterItem parameter="delay" type="integer">
                Delay in <i>ms</i> between your intro/outro sounds and the audio
                notification itself in 100ms steps from <code>0</code> to{" "}
                <code>1000</code>.
              </ApiParameterItem>

              <ApiParameterItem parameter="skipCache" type="boolean">
                Set to <code>true</code> Quak API server will not search for a
                cached version of the notification. Therefore it will be
                processed again which is slower. Default: <code>false</code>
              </ApiParameterItem>
            </>
          )}
        </tbody>
      </Table>

      <p>
        Please check your{" "}
        <a href="https://app.quak.sh/defaults" target="_blank">
          global user defaults
        </a>{" "}
        for <code>targets</code>, <code>volume</code>, <code>intro</code>,{" "}
        <code>outro</code>, <code>voice</code> and <code>delay</code>.
      </p>
    </>
  )
}

ApiParameterTable.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ApiParameterTable
