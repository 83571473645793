import bash from "./bash/all"
import javascript from "./javascript/all"
import ruby from "./ruby/all"
import python from "./python/all"
import php from "./php/all"

export default {
  bash: bash,
  javascript: javascript,
  ruby: ruby,
  python: python,
  php: php,
}
