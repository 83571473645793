import parser from "./parser"

// options: apiKey, apiBaseUrl, endpoint, data

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `    "${attr}": ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n  }"

  return `curl -X POST \\
  ${options.apiBaseUrl}/${options.endpoint} \\
  -H 'Content-Type: application/json' \\
  -H 'X-API-Key: ${options.apiKey}' \\
  -d '${dataOutput}'`
}

const postWithFile = options => {
  let dataOutput = "params={ "

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `"${attr}": ${parser(val)}`
  })

  dataOutput += dataText.join(", ")
  dataOutput += " }"

  if (dataText.length === 0) {
    dataOutput = "params={}"
  }

  const fileOutput = `file=@${options.data.file}`

  return `curl -X POST \\
  ${options.apiBaseUrl}/${options.endpoint} \\
  -H 'X-API-Key: ${options.apiKey}' \\
  -F '${dataOutput}' \\
  -F '${fileOutput}'`
}

export default {
  post,
  postWithFile,
}

//
// curl \
//   -H 'X-API-Key: b8e9d1f5-6dd5-4b47-ac77-281e74a71256' \
//   -F "params={}" \
//   -F "" \
//   https://sonos.eu.ngrok.io/v1/play/file
//
