import styled from "styled-components"
import media from "styled-media-query"

const Table = styled.table`
  thead {
    tr {
      th:first-child {
        width: 10%;
      }

      th:nth-child(2) {
        width: 10%;
      }

      th:nth-child(3) {
        width: 80%;
      }
    }
  }

  ${media.lessThan("medium")`
    td,
    th {
      padding-left: 0.54167rem;
      padding-right: 0.34167rem;

      &:first-child {
        padding-left: 0;
      }
    }
  `}
`
export default Table
