import _ from "lodash"

const parser = val => {
  if (_.isString(val)) {
    return `"${val}"`
  } else if (_.isInteger(val)) {
    return `${val}`
  } else if (_.isBoolean(val)) {
    return val ? "true" : "false"
  } else if (_.isNull(val)) {
    return "nil"
  } else if (_.isArray(val)) {
    let text = "["
    text += _.join(
      _.map(val, item => {
        return parser(item)
      }),
      ", "
    )
    text += "]"
    return text
  }

  return ""
}

export default parser
