import parser from "./parser"

const post = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data).map(function(attr, index) {
    const val = options.data[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")

  dataOutput += "\n}"

  return `var data = ${dataOutput};

var options = {
  url: "${options.apiBaseUrl}/${options.endpoint}",
  method: "POST",
  headers: {
    "X-API-Key": "${options.apiKey}"
  },
  contentType: "application/json",
  processData: false,
  data: JSON.stringify(data)
}

$.ajax(options).done(function (response) {
  console.log(response);
});`
}

const postWithFile = options => {
  let dataOutput = "{\n"

  const dataText = Object.keys(options.data.params).map(function(attr, index) {
    const val = options.data.params[attr]
    return `  ${attr}: ${parser(val)}`
  })

  dataOutput += dataText.join(",\n")
  dataOutput += "\n}"

  if (dataText.length === 0) {
    dataOutput = "{}"
  }

  return `// first create a blob from data URI, base64 string or getUserMedia
// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

const blob = new Blob([BLOB_DATA_HERE], { type : 'audio/mpeg' });

const params = ${dataOutput};

var formData = new FormData();
formData.append("params", JSON.stringify(params))
formData.append("file", blob)

var settings = {
  url: "${options.apiBaseUrl}/${options.endpoint}",
  method: "POST",
  headers: {
    "X-API-Key": "${options.apiKey}"
  },
  contentType: false,
  mimeType: "multipart/form-data",
  processData: false,
  dataType: "json",
  data: formData
}

$.ajax(settings).done(function (response) {
  console.log(response);
});`
}

export default { post, postWithFile }
